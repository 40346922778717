import * as tslib_1 from "tslib";
import { BreakpointObserver } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService, ImageService, PlatformHelperService, PropertyTypeHelperService } from '@app/core';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { IonSlides } from '@ionic/angular';
import { faChevronRight, faChevronLeft, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { SpacesParameters, SpaceFilterCondition, SpaceConditionsTypes } from '@app/models/spot-buildings/spot-available-spaces';
import { CommonGenericService } from '@app/core/common.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { parseInt } from 'lodash';
import { environment } from '@env/environment';
import { SpotBuildingSpaceService } from '../../services/spaces.service';
import { IndustrialLeaseType } from '../../../models/spot-buildings/spot-available-spaces';
import { RequestInformationType } from '../../../models/request-information.model';
import { LocalNumberPipe } from '@app/shared/pipes/number-pipe';
import { RoutingHelperService } from '../../../core/helpers/routing-helper.service';
import { AuthenticationService } from '@app/core/auth';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { KoworkingTypes } from '@app/models/koworking-types.model';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
import { SizeIcon } from '../../../models/shared/shared-enums.model';
import { MetaService } from '@app/core/seo';
import { HomePageAppService } from '../../../shared/services/homepageapp.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
export class BuildingsDetailPromotedComponent {
    constructor(activatedRoute, createSpotBuildingsService, imgService, i18NService, dialog, breakpointObserver, nearbyListingSvc, spotBuildingsService, propertyHelperService, commonService, popOverCtrlHelper, router, spacesService, _decimalPipe, platformConfigHelper, routingHelperSrv, auth, userActivityService, metaService, analyticsService, homepageAppSrv) {
        this.activatedRoute = activatedRoute;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.imgService = imgService;
        this.i18NService = i18NService;
        this.dialog = dialog;
        this.breakpointObserver = breakpointObserver;
        this.nearbyListingSvc = nearbyListingSvc;
        this.spotBuildingsService = spotBuildingsService;
        this.propertyHelperService = propertyHelperService;
        this.commonService = commonService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.router = router;
        this.spacesService = spacesService;
        this._decimalPipe = _decimalPipe;
        this.platformConfigHelper = platformConfigHelper;
        this.routingHelperSrv = routingHelperSrv;
        this.auth = auth;
        this.userActivityService = userActivityService;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.homepageAppSrv = homepageAppSrv;
        this.isMobile = false;
        this.sticky = false;
        this.maxSticky = false;
        this.mapOptions = {};
        this.markers = [];
        this.ORANGE_PIN = this.nearbyListingSvc.orangePin;
        this.services = [];
        this.spacesParameters = [];
        this.originalSpaces = [];
        this.showShortDesciption = true;
        this.position1 = 0;
        this.position2 = 0;
        this.positionTop = 0;
        this.cont = 0;
        this.features = 0;
        this.hiddeMoreFeatures = false;
        this.topPosition = 0;
        this.floatingDivHeight = 0;
        this.p3 = 0;
        this.SpotPhotos = [];
        this.isAdmin = false;
        this.showShortSpace = true;
        this.hiddeMoreSpaces = false;
        this.contactEmail = false;
        this.whatsappPhone = false;
        this.contactEmail2 = false;
        this.whatsappPhone2 = false;
        this.filter = 0;
        this.spacesCoworking = [];
        this.coworkingTypes = new KoworkingTypes();
        this.filter1 = false;
        this.filter2 = false;
        this.filter3 = false;
        this.filter4 = false;
        this.seeSuit = true;
        this.seePositions = true;
        this.favSizeIcon = SizeIcon.LG;
        this.spaceOffMarket = [1, 2, 3, 4, 5, 6];
        this.vacantAreaList = [];
        this.countBomaArea = false;
        this.scrollEvent = (event) => {
            if (!this.isMobile) {
                if (this.cont == 0) {
                    this.cont++;
                    this.position1 = document.getElementById('position1').getBoundingClientRect().top - 155;
                }
                if (event.srcElement.scrollTop > this.position1) {
                    if (event.srcElement.scrollTop > this.position1 &&
                        document.getElementById('position2').getBoundingClientRect().top >
                            document.getElementById('position3').getBoundingClientRect().height + 190) {
                        this.sticky = true;
                        this.maxSticky = false;
                    }
                    else {
                        if (document.getElementById('position2').getBoundingClientRect().top <=
                            document.getElementById('position3').getBoundingClientRect().height + 190) {
                            this.sticky = false;
                            this.maxSticky = true;
                        }
                    }
                }
                else {
                    this.sticky = false;
                    this.maxSticky = false;
                }
            }
        };
        this.betweenFilter = (value, minValue, maxValue) => {
            return value >= minValue && value <= maxValue;
        };
        this.greaterOrEqualFilter = (value, maxValue) => {
            return value >= maxValue;
        };
        this.betweenDatesFilter = (value, minValue, maxValue) => {
            if (minValue == null) {
                return value <= maxValue;
            }
            return value >= minValue && value <= maxValue;
        };
        this.betweenPerAreaFilter = (price, area, minValue, maxValue) => {
            return area && area > 0 && price / area >= minValue && price / area <= maxValue;
        };
        this.equalsFilter = (fieldValue, expectedValue) => expectedValue && fieldValue ? fieldValue == expectedValue : true;
        this.booleanFilter = (fieldValue, expectedValue) => (expectedValue ? fieldValue == expectedValue : true);
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
    }
    ngOnInit() {
        this.sharedIcon = faShareAlt;
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
        this.mapOptions = {
            singleMarker: false,
            ignoreZoom: false
        };
        this.getParamsUrl();
        this.getSpacesFilter();
        window.addEventListener('scroll', this.scrollEvent, true);
        this.isAdmin = this.checkSpotAdmin();
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    }
    ngAfterViewInit() {
        // Wait until the view is initialized before checking the query param
        this.activatedRoute.queryParams.subscribe(params => {
            const scrollInto = params['scrollIntoVac'];
            if (scrollInto) {
                this.scrollToDiv();
            }
        });
    }
    getSpacesFilter() {
        const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras) {
            if (navigation.extras.state) {
                const localStorageKey = navigation.extras.state.localStorageKey;
                const state = navigation.extras.state;
            }
        }
        else {
            this.spacesFilter = this.spacesService.getSpacesFilter();
            this.spacesService.saveSpacesFilter(undefined);
        }
        this.currentCoworkingTypeFilter = this.spacesFilter ? this.spacesFilter.coworkingType : null;
    }
    generateNearByMarker() {
        const currentListingMarker = this.nearbyListingSvc.createBasicMarker(this.buildingDetail.building, this.ORANGE_PIN);
        this.markers = [currentListingMarker];
    }
    cssSticky() {
        return {
            position: 'fixed',
            top: '195px',
            overflow: 'hidden',
            'z-index': '10'
        };
    }
    cssMaxSticky() {
        return {
            position: 'absolute',
            bottom: '-10px'
        };
    }
    cssImgServiceMobile() {
        return {
            width: window.screen.width - 80 + 'px',
            height: ((window.screen.width - 80) / 4) * 3 + 'px'
        };
    }
    buildTitleMetatag(listing) {
        let metaTagTitle = '';
        // Listing Type
        const listingTypeKey = listing.type === 'L'
            ? 'global.list-your-property.lease'
            : listing.type === 'S'
                ? 'global.list-your-property.sale'
                : listing.type === 'C'
                    ? 'global.list-your-property.coworking'
                    : '';
        const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());
        // Property Type
        let propertyTypeTag = '';
        const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
        propertyTypeTag = this.i18NService.get(propertyTypeKey);
        const propertyTypeTitleTag = propertyTypeTag;
        // Building Name
        const buildingName = this.i18NService.getTranslation(listing.propertyName);
        metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;
        // KeyWord Metatag
        let keywordsMetaTag = '';
        let companyName = '';
        const keywordsMetaTags = [];
        keywordsMetaTags.push(buildingName);
        keywordsMetaTags.push(listingTypeTag);
        keywordsMetaTags.push(propertyTypeTag);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle, keywordsMetaTag };
    }
    registerUserActivity(userActivityType, brokerId, listingId) {
        const userActivity = {
            module: userActivityType,
            brokerId: brokerId,
            buildingListingId: listingId
        };
        this.userActivityService.saveUserActivity(userActivity);
    }
    getParamsUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.buildingDetail = this.spotBuildingInput;
                this.buildingDetail.propertyName = this.i18NService.getTranslation(this.buildingDetail.propertyName);
                this.buildingDetail.address = this.i18NService.getTranslation(this.buildingDetail.address);
                this.buildingDetail.building.address = this.i18NService.getTranslation(this.buildingDetail.building.address);
                this.registerUserActivity(UserActivityType.PAGEVIEW_LISTING, null, this.buildingDetail.id);
                this.vacantAreaList = yield this.spotBuildingsService.getBuildingRentRolls(this.buildingDetail.building.id);
                this.countBomaArea = this.vacantAreaList.filter((boma) => boma.bomaArea != null);
                this.spotBuildingsService.goToSpotDetailsURL(this.buildingDetail.id);
                if (window.screen.width < 1000) {
                    this.isMobile = true;
                }
                this.sbSliderFilters = this.getSpotBuildingSliderFilters(this.spotBuildingInput);
                if (this.buildingDetail.comments) {
                    this.description = this.i18NService.getTranslation(this.buildingDetail.comments);
                }
                this.buildingDetail.building.buildingType.name =
                    this.buildingDetail.building.buildingType.id == 1001
                        ? this.propertyHelperService.getPropertyTypeTranslationName(1001)
                        : this.i18NService.getTranslation(this.buildingDetail.building.buildingType.name);
                if (this.buildingDetail.building.buildingSubType) {
                    this.buildingDetail.building.buildingSubType.name = this.i18NService.getTranslation(this.buildingDetail.building.buildingSubType.name);
                }
                if (this.buildingDetail.industrialStatus) {
                    this.buildingDetail.industrialStatus.name = this.i18NService.getTranslation(this.buildingDetail.industrialStatus.name);
                }
                this.generateNearByMarker();
                this.countFeatures();
                if (this.buildingDetail && this.buildingDetail.entireSale) {
                    if (this.buildingDetail.investmentOpportunity) {
                        this.investmentOpportunity = this.i18NService.getTranslation(this.buildingDetail.investmentOpportunity);
                    }
                    this.capRate = this.buildingDetail.capRate
                        ? this.commonService.formatNumberTo(this.buildingDetail.capRate, 2)
                        : '0.00';
                    this.percentageLeased = this.buildingDetail.percentageLeased
                        ? this.commonService.formatNumberTo(this.buildingDetail.percentageLeased)
                        : '0.00';
                    if (this.buildingDetail.majorTenants && this.buildingDetail.majorTenants.length > 0) {
                        this.majorTenants = this.buildingDetail.majorTenants.split(',');
                        if (this.majorTenants.length > 3) {
                            this.majorTenants = this.majorTenants.slice(0, 3);
                        }
                    }
                }
                if (this.buildingDetail.buildingListingPhotos) {
                    this.SpotPhotos = this.buildingDetail.buildingListingPhotos.map(blp => {
                        const photo = {
                            id: blp.id,
                            croppedImageId: blp.croppedImage ? blp.croppedImage.id : null,
                            imageId: blp.image ? blp.image.id : null,
                            orderId: blp.orderId,
                            originalImageId: blp.originalImage ? blp.originalImage.id : null
                        };
                        return photo;
                    });
                }
                //this.metaService.setSpotBuildingMetaTags(this.buildingDetail, this.SpotPhotos);
                this.createSpotBuildingsService
                    .getSpaceConditions(this.buildingDetail.id)
                    .then((response) => {
                    this.spacesParameters = response;
                    if (!this.spacesParameters || this.spacesParameters.length == 0) {
                        return;
                    }
                    else {
                        if (this.spacesParameters.length > 3) {
                            this.hiddeMoreSpaces = false;
                        }
                        else {
                            this.hiddeMoreSpaces = true;
                            this.showShortSpace = false;
                        }
                    }
                    this.originalSpaces = [...response];
                    let selected = [];
                    const filters = {
                        totalAreaFilter: (space) => this.betweenFilter(space.area, this.spacesFilter.totalAreaIni, this.spacesFilter.totalAreaEnd),
                        totalAskingPriceFilter: (space) => this.betweenFilter(space.askingPrice, this.spacesFilter.totalAskingPriceIni, this.spacesFilter.totalAskingPriceEnd),
                        askingPricePerAreaFilter: (space) => this.betweenPerAreaFilter(space.askingPrice, space.area, this.spacesFilter.askingPricePerAreaMin, this.spacesFilter.askingPricePerAreaMax),
                        totalmonthlyRentFilter: (space) => this.betweenFilter(this.spacesService.getTotalMonthlyRent(space), this.spacesFilter.totalMonthlyRentIni, this.spacesFilter.totalMonthlyRentEnd),
                        monthlyRentPerAreaFilter: (space) => this.betweenPerAreaFilter(space.monthlyAskingRent, space.area, this.spacesFilter.monthlyRentPerAreaMin, this.spacesFilter.monthlyRentPerAreaMax),
                        includeColdStorageFilter: (space) => this.booleanFilter(space.coldStorage, this.spacesFilter.includeColdStorage),
                        skyLightsSpaceFilter: (space) => this.booleanFilter(space.skyLights, this.spacesFilter.skyLightsSpace),
                        subLeaseFilter: (space) => this.equalsFilter(space.industrialLeaseType, this.spacesFilter.subLease ? IndustrialLeaseType.SUBLEASE : null),
                        dataCenterConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.DATA_CENTER),
                        forRetailUseConditionIndustrialFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FOR_RETAIL_USE_I),
                        flexConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FLEX),
                        manufacturingConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.MANUFACTURING),
                        logisticsConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.LOGISTICS),
                        preBuildConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PREBUILD),
                        plugAndPlayConditionOfficeFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PLUG_AND_PLAY_O),
                        fullFloorConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FULL_FLOOR),
                        forRetailUseConditionOfficeFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FOR_RETAIL_USE_O),
                        coreAndShellConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.CORE_AND_SHEEL),
                        furnishedConditionFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FURNISHED),
                        plugAndPlayConditionCoworkingFilter: (space) => this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PLUG_AND_PLAY_C),
                        positionsFilter: (space) => this.greaterOrEqualFilter(space.positions, this.spacesFilter.positions),
                        availabilityDateFilter: (space) => this.betweenDatesFilter(space.availabilityDate, null, this.spacesFilter.availabilityDate),
                        parkingAvailableFilter: (space) => this.booleanFilter(space.parkingAvailable, this.spacesFilter.parkingAvailable),
                        privateOutdoorFilter: (space) => this.booleanFilter(space.privateOutdoor, this.spacesFilter.privateOutdoorSpace),
                        conferenceRoomFilter: (space) => this.booleanFilter(space.conferenceRoomAccess, this.spacesFilter.conferenceRoom),
                        monthlyCostPerPersonConditionFilter: (space) => this.betweenFilter(space.monthlyCost, this.spacesFilter.monthlyCostPerPersonMin, this.spacesFilter.monthlyCostPerPersonMax),
                        coworkingTypeFilter: (space) => this.equalsFilter(space.coworkingType, this.currentCoworkingTypeFilter)
                    };
                    if (this.spacesFilter) {
                        if (this.spacesFilter.totalAreaIni && this.spacesFilter.totalAreaEnd) {
                            selected.push(filters.totalAreaFilter);
                        }
                        if (this.spacesFilter.totalAskingPriceIni && this.spacesFilter.totalAskingPriceEnd) {
                            selected.push(filters.totalAskingPriceFilter);
                        }
                        if (this.spacesFilter.totalMonthlyRentIni && this.spacesFilter.totalMonthlyRentEnd) {
                            selected.push(filters.totalmonthlyRentFilter);
                        }
                        if (this.spacesFilter.askingPricePerAreaMin && this.spacesFilter.askingPricePerAreaMax) {
                            selected.push(filters.askingPricePerAreaFilter);
                        }
                        if (this.spacesFilter.monthlyRentPerAreaMin && this.spacesFilter.monthlyRentPerAreaMax) {
                            selected.push(filters.monthlyRentPerAreaFilter);
                        }
                        // Coworking
                        if (this.spacesFilter.positions) {
                            selected.push(filters.positionsFilter);
                        }
                        if (this.spacesFilter.availabilityDate) {
                            selected.push(filters.availabilityDateFilter);
                        }
                        if (this.spacesFilter.conferenceRoom) {
                            selected.push(filters.conferenceRoomFilter);
                        }
                        if (this.spacesFilter.parkingAvailable) {
                            selected.push(filters.parkingAvailableFilter);
                        }
                        if (this.spacesFilter.privateOutdoorSpace) {
                            selected.push(filters.privateOutdoorFilter);
                        }
                        if (this.spacesFilter.monthlyCostPerPersonMin != null && this.spacesFilter.monthlyCostPerPersonMin > 0) {
                            if (this.spacesFilter.monthlyCostPerPersonMax != null && this.spacesFilter.monthlyCostPerPersonMax > 0) {
                                selected.push(filters.monthlyCostPerPersonConditionFilter);
                            }
                        }
                        // Flags
                        if (this.spacesFilter.includeColdStorage) {
                            selected.push(filters.includeColdStorageFilter);
                        }
                        if (this.spacesFilter.skyLightsSpace) {
                            selected.push(filters.skyLightsSpaceFilter);
                        }
                        if (this.spacesFilter.subLease) {
                            selected.push(filters.subLeaseFilter);
                        }
                        // Conditions
                        if (this.spacesFilter.datacenterCondition) {
                            selected.push(filters.dataCenterConditionFilter);
                        }
                        if (this.spacesFilter.forRetailUseConditionIndustrial) {
                            selected.push(filters.forRetailUseConditionIndustrialFilter);
                        }
                        if (this.spacesFilter.flexCondition) {
                            selected.push(filters.flexConditionFilter);
                        }
                        if (this.spacesFilter.manufacturingCondition) {
                            selected.push(filters.manufacturingConditionFilter);
                        }
                        if (this.spacesFilter.logisticsCondition) {
                            selected.push(filters.logisticsConditionFilter);
                        }
                        if (this.spacesFilter.preBuildCondition) {
                            selected.push(filters.preBuildConditionFilter);
                        }
                        if (this.spacesFilter.plugAndPlayConditionOffice) {
                            selected.push(filters.plugAndPlayConditionOfficeFilter);
                        }
                        if (this.spacesFilter.fullFloorCondition) {
                            selected.push(filters.fullFloorConditionFilter);
                        }
                        if (this.spacesFilter.forRetailUseConditionOffice) {
                            selected.push(filters.forRetailUseConditionOfficeFilter);
                        }
                        if (this.spacesFilter.coreAndShellCondition) {
                            selected.push(filters.coreAndShellConditionFilter);
                        }
                        if (this.spacesFilter.furnishedConditionCoworking) {
                            selected.push(filters.furnishedConditionFilter);
                        }
                        if (this.spacesFilter.plugAndPlayConditionCoworking) {
                            selected.push(filters.plugAndPlayConditionCoworkingFilter);
                        }
                        if (this.currentCoworkingTypeFilter) {
                            selected.push(filters.coworkingTypeFilter);
                        }
                    }
                    if (selected && selected.length > 0) {
                        if (this.isForCoworking()) {
                            this.spacesParameters = this.spacesParameters.filter(space => selected.every(f => f(space)));
                        }
                        else {
                            this.spacesParameters = this.spacesParameters.filter(space => selected.some(f => f(space)));
                        }
                    }
                    if (this.buildingDetail.type == 'C') {
                        const filterItem = this.findIndexFromCurrentCoworkingSpaceTypeFilter();
                        this.coworkingFilter(filterItem, true);
                    }
                });
            }
            catch (e) {
                console.error('Error : ', e);
            }
        });
    }
    scrollToDiv() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Wait for 3 seconds (3000 milliseconds) before scrolling
            yield this.delay(1200);
            const element = document.getElementById('vacantAreaDiv');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        });
    }
    // Helper function to introduce a delay
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    findIndexFromCurrentCoworkingSpaceTypeFilter() {
        const coworkingSpaceTypeFilter = this.currentCoworkingTypeFilter;
        let filterItem = 0;
        if (coworkingSpaceTypeFilter) {
            switch (coworkingSpaceTypeFilter) {
                case CoworkingSpaceType.PRIVATE_OFFICE:
                    filterItem = 1;
                    break;
                case CoworkingSpaceType.DEDICATED_WORKSPACE:
                    filterItem = 2;
                    break;
                case CoworkingSpaceType.OPEN_WORKSPACE:
                    filterItem = 3;
                    break;
                case CoworkingSpaceType.FULL_FLOOR_OFFICES:
                    filterItem = 4;
                    break;
            }
        }
        return filterItem;
    }
    outSideSearch() {
        this.spacesParameters = this.originalSpaces;
        if (this.isForCoworking()) {
            const coworkingTypeFilterId = this.findIndexFromCurrentCoworkingSpaceTypeFilter();
            this.coworkingFilter(coworkingTypeFilterId, true);
        }
    }
    getAvailableAreaText() {
        return this.commonService.getAvailableAreaText(this.buildingDetail.spaceRangesDTO);
    }
    getAskingRent() {
        return this.commonService.getAskingRent(this.buildingDetail.spaceRangesDTO);
    }
    getAskingRentPerArea() {
        return this.commonService.getAskingRentPerArea(this.buildingDetail.spaceRangesDTO);
    }
    getAskingPrice() {
        return this.commonService.getAskingPrice(this.buildingDetail.spaceRangesDTO);
    }
    getAskingPricePerArea() {
        return this.commonService.getAskingPricePerArea(this.buildingDetail.spaceRangesDTO);
    }
    getMonthlyRentPerPerson() {
        return this.commonService.getMonthlyRentPerPerson(this.buildingDetail.spaceRangesDTO);
    }
    getPositions() {
        return this.commonService.getPositions(this.buildingDetail.spaceRangesDTO);
    }
    removeHTMLCode(htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    }
    openCard2() {
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.buildingDetail,
            panelClass: 'custom-modalbox'
        });
    }
    openGalleryImg(event) {
        let photos = [];
        if (this.buildingDetail.buildingListingPhotos) {
            photos = this.buildingDetail.buildingListingPhotos.map(blp => {
                const photo = {
                    id: blp.id,
                    croppedImageId: blp.croppedImage ? blp.croppedImage.id : null,
                    imageId: blp.image ? blp.image.id : null,
                    orderId: blp.orderId,
                    originalImageId: blp.originalImage ? blp.originalImage.id : null
                };
                return photo;
            });
        }
        this.popOverCtrlHelper.openGalleryImageComponent(event, photos, this.buildingDetail.propertyName);
    }
    getMediumImg(id) {
        return this.imgService.mediumThumbnail(id);
    }
    getCroppedOrOriginalImage(buildinListingPhoto) {
        if (buildinListingPhoto) {
            if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
                return buildinListingPhoto.croppedImage.id;
            }
            return buildinListingPhoto.image.id;
        }
    }
    getPreviousPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const index = yield this.photoSlider.getActiveIndex();
            this.photoSlider.slidePrev();
        });
    }
    getNextPhoto() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const index = yield this.photoSlider.getActiveIndex();
            this.photoSlider.slideNext();
        });
    }
    goBack() {
        this.routingHelperSrv.navigateToSearchPage(true);
    }
    seeMoreAction(event, space) {
        this.popOverCtrlHelper.openSpacesDetailPopover(event, space, this.buildingDetail);
    }
    alterDescriptionText() {
        this.showShortDesciption = !this.showShortDesciption;
    }
    alterSpaceText() {
        this.showShortSpace = !this.showShortSpace;
    }
    openBuilding() {
        let url = `${environment.serverBaseUrl}building/${this.buildingDetail.building.id}`;
        window.open(url);
    }
    openRequestInfo(pEvent, informationType) {
        let brokers = [];
        let sentEmailTo = [];
        if (this.buildingDetail.brokers) {
            this.buildingDetail.brokers.forEach((broker) => {
                const agentBroker = {
                    firstNameAndLastName: broker.firstNameAndLastName,
                    phoneNumber: broker.phoneNumber,
                    photoId: broker.photoId,
                    userEmail: broker.userEmail
                };
                brokers.push(agentBroker);
                sentEmailTo.push(broker.userEmail);
            });
        }
        const requestInfoInput = {
            requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
            companyLogoImageId: this.buildingDetail.companyLogoImageId,
            agents: brokers,
            sentEmailTo: sentEmailTo,
            spotId: this.buildingDetail.id,
            requestType: RequestInformationType.REQ_INFO
        };
        this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
    }
    requestTourAction(pEvent) {
        let brokers = [];
        let sentEmailTo = [];
        if (this.buildingDetail.brokers) {
            this.buildingDetail.brokers.forEach((broker) => {
                const agentBroker = {
                    firstNameAndLastName: broker.firstNameAndLastName,
                    phoneNumber: broker.phoneNumber,
                    photoId: broker.photoId,
                    userEmail: broker.userEmail
                };
                brokers.push(agentBroker);
                sentEmailTo.push(broker.userEmail);
            });
        }
        const requestInfoInput = {
            requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
            companyLogoImageId: this.buildingDetail.companyLogoImageId,
            agents: brokers,
            sentEmailTo: sentEmailTo,
            spotId: this.buildingDetail.id,
            requestType: RequestInformationType.REQ_TOUR
        };
        this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
    }
    openVideoTour(pEvent) {
        if (this.buildingDetail.vrTourUrl) {
            this.userActivityService.trackSpotBuildingVirtualTour(this.buildingDetail.id);
            this.popOverCtrlHelper.openVideoTourPopup(pEvent, this.buildingDetail.vrTourUrl, this.buildingDetail.propertyName, this.isMobile, (error) => {
                console.error('Callback from video tour: ', error);
                this.spotBuildingsService.logError(error);
            });
        }
    }
    countFeatures() {
        if (this.buildingDetail.twentyFourHourSecurity) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.bicycleRack) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.fiberOptic) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.blockAndSteelConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.blockConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.centralAirConditioning) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.crossDocking) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.electricGenerator) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.heliport) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.multiTenant) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.petFriendly) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.singleTenant) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.sluiceGate) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.sprinklers) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.steelConstruction) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.leedStatus) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.truckYard) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.mailPackageHandling) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.enhancedCleaning) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.barista) {
            this.features = this.features + 1;
        }
        if (this.buildingDetail.cafeteria) {
            this.features = this.features + 1;
        }
        if (!this.isMobile && this.features <= 6) {
            this.hiddeMoreFeatures = true;
        }
        else {
            if (this.isMobile && this.features <= 3) {
                this.hiddeMoreFeatures = true;
            }
            else {
                this.hiddeMoreFeatures = false;
            }
        }
    }
    validateDecimal(value, type) {
        if (value == 0 || value == null) {
            return this.i18NService.get('global.list-your-property.negotiable');
        }
        if (type) {
            return type == 1 ? 'R$ ' + this.transform(value) : this.transform(value) + ' R$/m²';
        }
        else {
            return this.transform(value);
        }
    }
    transform(value) {
        if (value % 1 == 0) {
            return this._decimalPipe.transform(value, `1.0-0`);
        }
        else {
            return this._decimalPipe.transform(value, `1.2-2`);
        }
    }
    editBuilding() {
        window.open(`/spot-buildings/create/${this.buildingDetail.type == 'L' ? 'lease' : this.buildingDetail.type == 'C' ? 'coworking' : 'sale'}/${this.buildingDetail.building.id}`, '_self');
    }
    checkSpotAdmin() {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
            return true;
        }
        else {
            return false;
        }
    }
    openCompanyDetails(event) {
        this.routingHelperSrv.navigateToCompanyDetailsPage(this.buildingDetail.offeredByCompany.id);
    }
    /*  @HostListener('window:scroll')
     scrollUp() {
       window.scrollTo(0, 0);
     } */
    showContactEmail(notSubscribe = null, broker = null) {
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(2);
                return;
            }
        }
        if (broker) {
            this.contactEmail2 = true;
        }
        else {
            this.contactEmail = true;
        }
        /*  this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL); */
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    }
    showBrokerWhatsapp(notSubscribe = null, broker = null) {
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(1);
                return;
            }
        }
        if (broker) {
            this.whatsappPhone2 = true;
        }
        else {
            this.whatsappPhone = true;
        }
        /* this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP); */
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    }
    openShowMoreLogInModal(id) {
        const subscribeDialog = this.dialog.open(SubscribeModalComponent, {
            width: '400px',
            panelClass: 'my-custom-dialog-class',
            data: id
        });
        subscribeDialog.afterClosed().subscribe(val => {
            if (val != undefined) {
                switch (val) {
                    case 1:
                        this.showBrokerWhatsapp(val);
                        break;
                    case 2:
                        this.showContactEmail(val);
                        break;
                    default:
                        return null;
                }
            }
        });
    }
    isForCoworking() {
        return this.buildingDetail.type == 'C';
    }
    coworkingFilter(filter, validateFilters = false) {
        const spaces = JSON.parse(JSON.stringify(this.spacesParameters));
        this.seeSuit = true;
        this.seePositions = true;
        if (validateFilters) {
            this.filter1 =
                this.spacesParameters.filter(val => {
                    return val.coworkingType == this.coworkingTypes.privateOffice;
                }).length > 0
                    ? true
                    : false;
            this.filter2 =
                this.spacesParameters.filter(val => {
                    return val.coworkingType == this.coworkingTypes.dedicatedWorkspace;
                }).length > 0
                    ? true
                    : false;
            this.filter3 =
                this.spacesParameters.filter(val => {
                    return val.coworkingType == this.coworkingTypes.openWorkspace;
                }).length > 0
                    ? true
                    : false;
            this.filter4 =
                this.spacesParameters.filter(val => {
                    return val.coworkingType == this.coworkingTypes.fullFloorOffices;
                }).length > 0
                    ? true
                    : false;
        }
        if (filter == 1 || (filter == 0 && this.filter1)) {
            this.spacesCoworking = spaces.filter((val) => {
                return val.coworkingType == this.coworkingTypes.privateOffice;
            });
            this.spacesCoworking = this.spacesCoworking.filter(val => {
                return (val.coworkingType = '{"pt-br": "buildinsDetail.privateOffice", "en":"buildinsDetail.privateOffice"}');
            });
            this.filter = 1;
            return;
        }
        if (filter == 2 || (filter == 0 && this.filter2)) {
            this.spacesCoworking = spaces.filter((val) => {
                return val.coworkingType == this.coworkingTypes.dedicatedWorkspace;
            });
            this.spacesCoworking = this.spacesCoworking.filter(val => {
                return (val.coworkingType =
                    '{"pt-br": "buildinsDetail.dedicatedWorkspace", "en":"buildinsDetail.dedicatedWorkspace"}');
            });
            this.filter = 2;
            return;
        }
        if (filter == 3 || (filter == 0 && this.filter3)) {
            this.spacesCoworking = spaces.filter((val) => {
                return val.coworkingType == this.coworkingTypes.openWorkspace;
            });
            this.spacesCoworking = this.spacesCoworking.filter(val => {
                return (val.coworkingType = '{"pt-br": "buildinsDetail.openWorkspace", "en":"buildinsDetail.openWorkspace"}');
            });
            this.filter = 3;
            this.seeSuit = false;
            return;
        }
        if (filter == 4 || (filter == 0 && this.filter4)) {
            this.spacesCoworking = spaces.filter((val) => {
                return val.coworkingType == this.coworkingTypes.fullFloorOffices;
            });
            this.spacesCoworking = this.spacesCoworking.filter(val => {
                return (val.coworkingType =
                    '{"pt-br": "buildinsDetail.fullFloorOffices", "en":"buildinsDetail.fullFloorOffices"}');
            });
            this.filter = 4;
            this.seeSuit = false;
            this.seePositions = false;
            return;
        }
        if (this.spacesCoworking.length > 3) {
            this.hiddeMoreSpaces = false;
            this.showShortSpace = true;
        }
        else {
            this.hiddeMoreSpaces = true;
            this.showShortSpace = false;
        }
    }
    getSpotBuildingSliderFilters(spotBuilding) {
        let filters = {
            listingClasses: ['FEATURED', 'PREMIUM']
        };
        if (!spotBuilding || !spotBuilding.offeredByCompany)
            return filters;
        filters.companyId = this.buildingDetail.offeredByCompany.id || null;
        return filters;
    }
    goToSiilaSolutions(link) {
        if (link == 1) {
            window.open(this.homepageAppSrv.getMarketAnalyticsURL(), '_blank');
        }
        if (link == 2) {
            window.open(this.homepageAppSrv.getIndicesURL(), '_blank');
        }
        if (link == 3) {
            window.open(this.homepageAppSrv.getFiiDataProURL(), '_blank');
        }
    }
    goToMarketAnalytics() {
        window.open(`${environment.serverBaseUrl}building_preview/${this.buildingDetail.building.id}/`, 'blank');
    }
    cssToolbar() {
        if (this.isMobile) {
            return {
                position: 'unset'
            };
        }
        else {
            return {
                position: 'fixed'
            };
        }
    }
    shareCompany(event) {
        const url = window.location.href;
        const sharedTitle = this.buildingDetail.propertyName;
        this.userActivityService.trackSpotBuildingShare(this.buildingDetail.id);
        const modalTitle = this.i18NService.get('buildinsDetail.shareProperty');
        const input = { url, sharedTitle, modalTitle };
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    }
    openMarket() {
        window.open(`${environment.serverUrl}/marketanalytics/lang/${this.i18NService.getCurrentLanguage()}`);
    }
    openInvestor() {
        window.open(this.homepageAppSrv.getInstitutionalInvestorsURL());
    }
    openProduct() {
        window.open(`${environment.serverUrl}/products/lang/${this.i18NService.getCurrentLanguage()}`);
    }
    openTenant() {
        window.open(this.homepageAppSrv.getTenantsOccupiersURL());
    }
    openOwners() {
        window.open(this.homepageAppSrv.getOwnersDevelopersURL());
    }
}
