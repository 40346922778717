import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SpotBuildingsSearchService } from '../../../spot-buildings/services/spot-buildings-search.service';
import { I18nService } from '../../../core/i18n.service';
import { SpotBuildingSearchRequest } from '../../../models/spot-buildings/spot-building-search.model';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
export class HomeSearchControlComponent {
    constructor(router, routinHelperSrv, i18nService, spotBuildingSearchService, _ts) {
        this.router = router;
        this.routinHelperSrv = routinHelperSrv;
        this.i18nService = i18nService;
        this.spotBuildingSearchService = spotBuildingSearchService;
        this._ts = _ts;
        this.spotBuildingTypes = {
            lease: 'L',
            sale: 'S',
            coworking: 'C',
            company: 'CPY'
        };
        this.propertyTypes = {
            industrial: 1001,
            office: 2001
        };
        this.faChevronRight = faChevronRight;
        this.advertisements = [];
        this.active = 1;
        this.searchModel = {
            propertyTypeId: this.propertyTypes.office,
            spotBuildingType: this.spotBuildingTypes.lease,
            keyword: null,
            companyId: null
        };
        this.allCompaniesWithSpots = [];
        this.companyCtrl = new FormControl();
        this.leaseLabel = _ts.instant('global.list-your-property.forLeaseHome');
        this.saleLabel = _ts.instant('global.list-your-property.forSaleHome');
        this.companyLabel = _ts.instant('global.list-your-property.forCompanyHome');
        this.selectCompany = _ts.instant('global.list-your-property.selectCompany');
        this.spotBuildingSearchService.findAllSpotCompanies().then((res) => {
            this.allCompaniesWithSpots = res;
            this.companyCtrl.setValue('');
            this.filteredCompanies = this.companyCtrl.valueChanges.pipe(startWith(''), map(state => this._filterStates(state)));
        });
    }
    selectedState(event) {
        this.searchModel.companyId = null;
        const selectedCompanyName = event.option.value;
        const selectedCompany = this.allCompaniesWithSpots.filter((company) => company.name == selectedCompanyName);
        if (selectedCompany && selectedCompany.length > 0) {
            this.searchModel.companyId = selectedCompany[0].id;
            console.log(selectedCompany[0], this.searchModel);
        }
    }
    _filterStates(value) {
        const filterValue = this._normalizeValue(value.toLowerCase());
        if (value == '') {
            return [];
        }
        const filteredOptions = this.allCompaniesWithSpots
            .filter((state) => this._normalizeValue(state.name.toLowerCase()).includes(filterValue))
            .sort((a, b) => a.name.localeCompare(b.name));
        return filteredOptions;
    }
    highlightOption(option) {
        const filterValue = this._normalizeValue(this.companyCtrl.value.toLowerCase());
        const startIndex = this._normalizeValue(option.toLowerCase()).indexOf(filterValue);
        if (startIndex >= 0) {
            const endIndex = startIndex + filterValue.length;
            return (option.substring(0, startIndex) +
                '<span class="highlight-match" style="color: blue; font-weight: bold;">' +
                option.substring(startIndex, endIndex) +
                '</span>' +
                option.substring(endIndex));
        }
        return option;
    }
    getPropertyTypeKey(propertyTypeId) {
        switch (propertyTypeId) {
            case this.propertyTypes.industrial:
                return 'global.industrial';
            case this.propertyTypes.office:
                return 'global.office';
            default:
                return;
        }
    }
    portChange(event) {
        //console.log('Event: ', event);
    }
    isNotCompanySearch() {
        return this.searchModel.spotBuildingType != this.spotBuildingTypes.company;
    }
    isCompanySearch() {
        return this.searchModel.spotBuildingType == this.spotBuildingTypes.company;
    }
    setPropertyTypeId(type, listingType) {
        if (listingType) {
            this.setSpotBuildingType(listingType);
        }
        else {
            if (this.searchModel.spotBuildingType == this.spotBuildingTypes.coworking) {
                this.setSpotBuildingType(this.spotBuildingTypes.lease);
            }
        }
        this.searchModel = Object.assign({}, this.searchModel, { propertyTypeId: type, keyword: null, companyId: null });
    }
    setSpotBuildingType(tab) {
        this.searchModel = Object.assign({}, this.searchModel, { spotBuildingType: tab, keyword: null, companyId: null });
    }
    selectKeywordItem(item) {
        this.searchModel.keyword = item;
    }
    selectCompanySpot(id) {
        this.searchModel = Object.assign({}, this.searchModel, { companyId: id, keyword: null });
    }
    disableSearchBtn() {
        var disabled = false;
        if (this.isCompanyTabActive()) {
            if (!this.searchModel.companyId) {
                disabled = true;
            }
        }
        return disabled;
    }
    isCompanyTabActive() {
        return this.searchModel.spotBuildingType === this.spotBuildingTypes.company;
    }
    onSearch(advanced = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isCompanyTabActive()) {
                if (!this.searchModel.companyId) {
                    return;
                }
                this.routinHelperSrv.navigateToCompanyDetailsPage(this.searchModel.companyId);
                return;
            }
            const queryParams = {
                propertyType: this.searchModel.propertyTypeId,
                listingType: this.searchModel.spotBuildingType,
                keyword: this.searchModel.keyword,
                advanced
            };
            const filters = JSON.stringify(queryParams);
            if (filters) {
                this.spotBuildingSearchService.saveFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS, filters);
            }
            let req = new SpotBuildingSearchRequest();
            req.listingLeaseOrSale = [...this.searchModel.spotBuildingType];
            req.buildingTypes = [`${this.searchModel.propertyTypeId}`];
            req.keyword =
                this.searchModel.keyword && this.searchModel.keyword.type == 'neighborhood'
                    ? this.searchModel.keyword.name
                    : null;
            req.stateId = this.searchModel.keyword && this.searchModel.keyword.id ? this.searchModel.keyword.id : null;
            req.stateId = null;
            req.cityId = null;
            req.marketId = null;
            if (this.searchModel.keyword && this.searchModel.keyword.id) {
                const isStateType = this.searchModel.keyword.type == 'state';
                const isCityType = this.searchModel.keyword.type == 'city';
                const isMarketType = this.searchModel.keyword.type == 'market';
                if (isStateType) {
                    req.stateId = this.searchModel.keyword.id;
                }
                if (isCityType) {
                    req.stateId = this.searchModel.keyword.stateId;
                    req.cityId = this.searchModel.keyword.id;
                }
                if (isMarketType) {
                    req.marketId = this.searchModel.keyword.id;
                }
            }
            var response = yield this.spotBuildingSearchService.getUrlFromListingsSearchDto(req);
            let lang = this.i18nService.getCurrentLanguage();
            response.url = JSON.parse(response.url);
            const newUrl = `${response.url[lang]}${response.keyword ? '?keyword=' + response.keyword : ''}`;
            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                this.router.navigateByUrl(this.i18nService.addCurrentLanguageToPath(`${this.routinHelperSrv.getSpotSearchURL()}${newUrl}`));
            }
            else {
                this.router.navigateByUrl(`${this.routinHelperSrv.getSpotSearchURL()}${newUrl}`);
            }
        });
    }
    _normalizeValue(value) {
        const accentsMap = {
            á: 'a',
            é: 'e',
            í: 'i',
            ó: 'o',
            ú: 'u',
            à: 'a',
            è: 'e',
            ì: 'i',
            ò: 'o',
            ù: 'u',
            ä: 'a',
            ë: 'e',
            ï: 'i',
            ö: 'o',
            ü: 'u',
            â: 'a',
            ê: 'e',
            î: 'i',
            ô: 'o',
            û: 'u',
            ã: 'a',
            õ: 'o',
            ñ: 'n',
            ç: 'c',
            ā: 'a',
            ē: 'e',
            ī: 'i',
            ō: 'o',
            ū: 'u',
            ă: 'a',
            ĕ: 'e',
            ĭ: 'i',
            ŏ: 'o',
            ŭ: 'u',
            ą: 'a',
            ę: 'e',
            į: 'i',
            ų: 'u',
            å: 'a',
            ø: 'o',
            ș: 's',
            ț: 't',
            Ă: 'A',
            Ș: 'S',
            Ț: 'T'
        };
        return value.replace(/./g, c => accentsMap[c] || c);
    }
}
