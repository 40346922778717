import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '@app/core/auth';
import { I18nService } from '@app/core';
export class CanDeactivateGuard {
    constructor(alertCtrl, router, auth, i18nService) {
        this.alertCtrl = alertCtrl;
        this.router = router;
        this.auth = auth;
        this.i18nService = i18nService;
        this.validate = true;
        this.activeSession = true;
        this.$isLoggedIn = this.auth.$isLoginSubject;
        this.$isLoggedIn.subscribe(val => {
            console.log('another subscription :' + val);
            this.activeSession = val;
        });
    }
    canDeactivate(component, currentRoute, currentState, nextState) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.nextState = nextState;
            this.currentComponent = component;
            if (this.activeSession && component && !component.canDeactivate() && this.validate) {
                yield this.discardConfirmMessage();
                this.validate = true;
            }
            else {
                return true;
            }
            return false;
        });
    }
    discardConfirmMessage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const unsavedChangeHeaderLabel = this.i18nService.get('global.unsavedChangeHeaderLabel');
            const unsavedChangeTxt = this.i18nService.get('global.unsavedChangeBodyText');
            const cancelTxt = this.i18nService.get('global.unsavedCancel');
            const discardTxt = this.i18nService.get('global.unsavedDiscardChanges');
            const alert = yield this.alertCtrl.create({
                header: unsavedChangeHeaderLabel,
                subHeader: unsavedChangeTxt,
                cssClass: 'ion-alert-listings',
                buttons: [
                    {
                        text: cancelTxt,
                        role: 'Cancel',
                        handler: () => {
                            //console.log('Cancelar')
                        }
                    },
                    {
                        text: discardTxt,
                        handler: () => {
                            this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
                            this.validate = false;
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => {
                //this.router.navigateByUrl('/user-home/my-listings');
                this.validate = true;
            });
            yield alert.present();
        });
    }
}
