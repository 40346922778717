import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams, AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
export class AdminUpdatesComponent {
    constructor(router, purchaseCreditsService, popoverCtrl, navParams, registerService, _ts, creditService, _eventChecker, alertCtrl, purchaseService, i18nService, listingDetailService) {
        this.router = router;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.navParams = navParams;
        this.registerService = registerService;
        this._ts = _ts;
        this.creditService = creditService;
        this._eventChecker = _eventChecker;
        this.alertCtrl = alertCtrl;
        this.purchaseService = purchaseService;
        this.i18nService = i18nService;
        this.listingDetailService = listingDetailService;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.renewalChoice = 'activate';
    }
    ngOnInit() {
        this.getStatuses();
    }
    getStatuses() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.classes = [{ name: 'FEATURED', id: 0 }, { name: 'PREMIUM', id: 1 }, { name: 'PROMOTED', id: 2 }];
            this.statuses = yield this.getStatusType();
            this.statuses = yield this.getStatusTranslations(this.statuses);
            this.listing = this.navParams.data.listing;
            this.setAdminValues(this.listing);
            console.log(this.listing);
        });
    }
    getStatusType() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.registerService.getListingStatus().toPromise();
        });
    }
    getStatusTranslations(statuses) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            Object.keys(statuses).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                statuses[key].status = this.getI18nValue(statuses[key].status);
            }));
            return statuses;
        });
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
    getPopData() { }
    setAdminValues(listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let index = this.classes.findIndex((v) => {
                return v.name === listing.listingType;
            });
            this.listingClass = this.classes[index];
            /*let statusIndex = this.statuses.findIndex((v: any) => {
              return v.id === listing.listingStatus.id;
            });
            this.listingStatus = this.statuses[statusIndex];*/
            this.expirationDate = listing.endDate ? new Date(listing.endDate) : null;
        });
    }
    checkDate(ev) { }
    choiceSelection(event) {
        this.renewalChoice = event.detail.value;
    }
    displayAlert(approval) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: this._ts.instant('global.removeListing'),
                subHeader: this._ts.instant('global.removeListingText'),
                cssClass: 'ion-alert-listings',
                backdropDismiss: true,
                buttons: [
                    {
                        text: this._ts.instant('Cancel'),
                        role: 'Cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    },
                    {
                        text: this._ts.instant('Ok'),
                        handler: () => {
                            this.spotDeactivate();
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => { });
            yield alert.present();
        });
    }
    numDaysBetween(d1, d2) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let diff = d1.getTime() - d2.getTime();
            return diff / (1000 * 60 * 60 * 24);
        });
    }
    displayActivateAlert(approval) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let expired;
            let d1 = new Date();
            let d2 = new Date(this.listing.endDate);
            let differenceDate = yield this.numDaysBetween(d1, d2);
            differenceDate > 0 ? (expired = true) : (expired = false);
            const alert = yield this.alertCtrl.create({
                header: this._ts.instant('global.adminUpdates.activateListing'),
                subHeader: expired
                    ? this._ts.instant('global.adminUpdates.activateExpired')
                    : !this.listing.priorListingId
                        ? this._ts.instant('global.adminUpdates.activate')
                        : this._ts.instant('global.adminUpdates.activateRevision'),
                cssClass: 'ion-alert-listings',
                backdropDismiss: true,
                buttons: [
                    {
                        text: this._ts.instant('Cancel'),
                        role: 'Cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    },
                    {
                        text: this._ts.instant('Ok'),
                        handler: () => {
                            this.updateReactivatedListing();
                        }
                    }
                ]
            });
            alert.onDidDismiss().then(() => { });
            yield alert.present();
        });
    }
    updateReactivatedListing() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listing = yield this.purchaseService.reactivateListing(this.listing.id);
            if (listing) {
                this._eventChecker.updateListing$.next(listing);
                this.popoverCtrl.dismiss();
            }
        });
    }
    checkIfDate() {
        if (this.expirationDate) {
            if (this.expirationDate instanceof Date &&
                Object.prototype.toString.call(this.expirationDate) === '[object Date]') {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }
    spotDeactivate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let listing = yield this.purchaseCreditsService.spotDeactivate(this.listing.status.id === 8 ? this.listing.priorListingId : this.listing.id);
            console.log(listing);
            this._eventChecker.updateListing$.next(listing);
            if (this.listing.nextListingStatus && this.listing.nextListingStatus.id === 1) {
                this._eventChecker.removeListing$.next(this.listing.nextListingId);
            }
            if (this.listing.listingStatus.id === 1) {
                this._eventChecker.removeListing$.next(this.listing.id);
                let response = yield this.listingDetailService.getListingDetails(this.listing.priorListingId).toPromise();
                console.log(response);
                let updatedListing = response.listing;
                updatedListing.listingPhotos = [];
                updatedListing.buildingTitle = updatedListing.title;
                updatedListing.cityName = updatedListing.building.city.name;
                updatedListing.buildingTitle = updatedListing.building.title;
                updatedListing.buildingTypeId = updatedListing.building.buildingType.id;
                updatedListing.buildingId = updatedListing.building.id;
                updatedListing.buildingAddress = updatedListing.building.address;
                this._eventChecker.paUpdate$.next(updatedListing);
            }
            this.popoverCtrl.dismiss();
        });
    }
    update() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listing.listingType = this.listingClass.id;
            this.listing.endDate = this.expirationDate;
            let update = yield this.purchaseCreditsService.adminListingUpdate(this.listing);
            this._eventChecker.updateListing$.next(update);
            this.popoverCtrl.dismiss();
            if (this.listing.listingStatus.id === 4 &&
                this.listing.nextListingStatus &&
                this.listing.nextListingStatus.id === 4) {
                let updatedListing = yield this.listingDetailService.getListingDetails(this.listing.nextListingId);
                this._eventChecker.paUpdate$.next(updatedListing);
            }
        });
    }
    cancel() {
        this.popoverCtrl.dismiss();
    }
}
