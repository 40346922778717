import * as tslib_1 from "tslib";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService, AccountService } from '@app/core/auth';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';
export class HttpConfigInterceptor {
    constructor(auth, account, toastCtrl, i18nService) {
        this.auth = auth;
        this.account = account;
        this.toastCtrl = toastCtrl;
        this.i18nService = i18nService;
        this.toastPresent = false;
    }
    intercept(req, next) {
        const isRequestingToken = req.url.includes('token');
        const token = this.auth.getToken();
        if (token && !isRequestingToken) {
            req = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + JSON.parse(token)['access_token'])
            });
        }
        return next.handle(req).pipe(catchError((error) => {
            const errorMessage = error.error ? error.error.error : '';
            const userHasKeepConnected = this.account.getCurrentUser && this.account.getCurrentUser.keepConnected;
            const shouldRefreshToken = error.status === 401 && errorMessage === 'invalid_token' && userHasKeepConnected;
            if (isRequestingToken) {
                const grantTypeParam = (req.body.updates || []).find(({ param }) => param === 'grant_type');
                const isRefreshingToken = (grantTypeParam || {}).value === 'refresh_token';
                if (isRefreshingToken) {
                    console.info(this.i18nService.get('TokenExpiration.failRefreshToken'));
                    this.auth.logout();
                }
                return throwError(error);
            }
            if (error.status === 504 || error.status === 503) {
                this.showTimeoutErr();
                return throwError(error);
            }
            if (error.status === 401 && !shouldRefreshToken) {
                if (this.auth.hasToken() || this.account.getCurrentUser) {
                    this.auth.logout();
                    return throwError(error);
                }
                return throwError(error);
            }
            return throwError(error);
        }));
    }
    showTimeoutErr() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.toastPresent) {
                this.toastPresent = true;
                const toast = yield this.toastCtrl.create({
                    message: this.i18nService.get('global.user.message.timeoutError'),
                    duration: 3000,
                    animated: true,
                    keyboardClose: true,
                    cssClass: 'toast-alert'
                });
                toast.onDidDismiss().then(() => {
                    this.toastPresent = false;
                });
                return yield toast.present();
            }
        });
    }
}
