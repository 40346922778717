import * as tslib_1 from "tslib";
import { OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatStepper, MatSnackBar } from '@angular/material';
import { ListingService, EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
export class AddressComponent {
    constructor(listingService, eventCheckerSrv, i18NService, snackbar, zone) {
        this.listingService = listingService;
        this.eventCheckerSrv = eventCheckerSrv;
        this.i18NService = i18NService;
        this.snackbar = snackbar;
        this.zone = zone;
    }
    ngOnDestroy() {
        //this.eventCheckerSrv.updateBuildingLocation$.unsubscribe();
    }
    ngOnInit() {
        this.closeTxt = this.i18NService.get('Close');
        this.duplicateLocationTxt = this.i18NService.get('map.duplicateLocation');
        if (!this.actualProperty) {
            this.getStates();
            this.multiLangInit();
            this.clearInputs();
            this.addressForm.get('city').disable();
            this.detectBuildingLocationChanges();
        }
        else {
            this.clearInputs();
            this.getStates();
            this.buildingInfo = this.getBuildingAddress(this.actualProperty.id);
        }
    }
    clearInputs() {
        this.addressForm.controls['address'].setValue('');
        this.addressForm.controls['state'].setValue(null);
        this.addressForm.controls['city'].setValue(null);
        this.addressForm.controls['zip'].setValue('');
        this.addressForm.controls['buildingLocation'].setValue('');
        this.addressForm.get('state').enable();
        this.addressForm.get('address').enable();
        this.addressForm.get('city').enable();
        this.addressForm.get('zip').enable();
    }
    setReadOnly(building) {
        if (building) {
            this.buildingName = this.getI18nValue(building.address);
            this.addressForm.controls['address'].setValue(this.buildingName);
            this.addressForm.controls['state'].setValue(building.region.countryState.id);
            this.getCities(building.region.countryState.id);
            this.addressForm.get('state').disable();
            this.addressForm.get('address').disable();
            this.addressForm.get('city').disable();
            this.addressForm.get('zip').disable();
        }
        if (building.neighborhood) {
            this.addressForm.controls.neighborhood.setValue(building.neighborhood);
        }
    }
    getI18nValue(textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18NService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    }
    getBuildingAddress(buildingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.buildingInfo = yield this.listingService.getBuilding(buildingId);
            this.setReadOnly(this.buildingInfo);
        });
    }
    getStates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.states = yield this.listingService.getStates();
        });
    }
    getCities(stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.cities = yield this.listingService.getAllCitiesByState(stateId ? stateId : this.addressForm.value.state);
            if (stateId) {
                this.addressForm.controls.city.setValue(this.buildingInfo.city.id);
                this.addressForm.controls.zip.setValue(this.buildingInfo.postalCode);
            }
        });
    }
    getLoadedCity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    setCity() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.addressForm.controls['state'] && this.addressForm.controls['state'].value) {
                this.addressForm.get('city').enable();
                return this.getCities();
            }
            else {
                this.addressForm.get('city').disable();
            }
        });
    }
    multiLangInit() {
        this.addressOptions = {
            columnLabel: 'global.list-your-property.address',
            element: { input: true },
            formArrayName: 'address',
            required: true
        };
    }
    addressChange(value) {
        if (value.address.length > 0) {
            let mergedObject = {};
            let nullValues = false;
            Object.keys(value.address).forEach((key, idx) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let obj = value.address[key];
                if (obj['en'] === '' || obj['pt-br'] === '') {
                    nullValues = true;
                }
                else {
                    mergedObject = Object.assign(mergedObject, obj);
                }
            }));
            nullValues
                ? this.addressForm.controls['address'].setValue(null)
                : this.addressForm.controls['address'].setValue(JSON.stringify(mergedObject));
        }
        else {
            this.addressForm.controls['address'].setValue(null);
        }
    }
    detectBuildingLocationChanges() {
        this.eventCheckerSrv.updateBuildingLocation$.subscribe((buildingLocation) => {
            if (buildingLocation && buildingLocation.stateName) {
                const differentLocation = !this.addressForm.controls['buildingLocation'] ||
                    this.addressForm.controls['buildingLocation'].value != buildingLocation;
                if (this.states && differentLocation) {
                    this.addressForm.controls['buildingLocation'].setValue(buildingLocation);
                    const statesFound = this.states.filter((state) => JSON.parse(state.name).en === buildingLocation.stateName);
                    if (statesFound && statesFound.length > 0) {
                        const currentStateId = this.addressForm.controls['state'].value;
                        if (currentStateId === statesFound[0].id) {
                            this.setCityFromName(buildingLocation);
                        }
                        else {
                            this.addressForm.controls['state'].setValue(statesFound[0].id);
                            this.setCityFromName(buildingLocation);
                        }
                    }
                }
            }
        });
    }
    setCityFromName(buildingLocation) {
        if (buildingLocation && buildingLocation.cityName) {
            if (!this.cities || this.cities[0].countryState.id !== this.addressForm.controls['state'].value) {
                this.setCity().then(() => {
                    this.setCityFromLocationAndCheckForDuplicateBuildings(buildingLocation);
                });
            }
            else {
                this.setCityFromLocationAndCheckForDuplicateBuildings(buildingLocation);
            }
        }
        else {
            this.addressForm.controls['city'].setValue('');
        }
    }
    setCityFromLocationAndCheckForDuplicateBuildings(buildingLocation) {
        const cityName = buildingLocation.cityName;
        const citiesFound = this.cities.filter((city) => JSON.parse(city.name).en === cityName || JSON.parse(city.name)['pt-br'] === cityName);
        if (citiesFound && citiesFound.length > 0) {
            const cityId = citiesFound[0].id;
            this.addressForm.controls['city'].setValue(cityId);
            buildingLocation.cityId = cityId;
            this.checkDuplicateBuildingLocation(buildingLocation);
            console.log('Finishing City From name');
        }
    }
    checkDuplicateBuildingLocation(buildingLocation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.listingService.checkDuplicateBuildingLocation(buildingLocation).then((result) => {
                if (result && result.body) {
                    const nearbyLocations = result.body;
                    if (nearbyLocations.length > 0) {
                        this.zone.run(() => {
                            this.snackbar.open(this.duplicateLocationTxt, this.closeTxt, {
                                duration: 2000,
                                verticalPosition: 'bottom',
                                horizontalPosition: 'center',
                                panelClass: ['snack-background-spot']
                            });
                            console.log('Showing SnackBar');
                        });
                    }
                }
            });
        });
    }
}
