import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ImageService, PlatformHelperService, PropertyTypeHelperService, I18nService } from '@app/core';
import { Router } from '@angular/router';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { AppCarouselRestoreService } from '../carousel';
import { IonSlides, ToastController } from '@ionic/angular';
import { faStar, faAward, faBuilding, faWarehouse, faShareAltSquare, faHeart, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { EventCheckerService } from '@app/core/listings';
import { CommonGenericService } from '@app/core/common.service';
import { GenericMapService } from '@app/core/generic-map.service';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { environment } from '@env/environment';
import { ListingDetailService } from '@app/core/listings';
import { LoginFormDialogService } from '../../../shared/login';
import { AuthenticationService } from '../../../core/auth/auth.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { ListingOfferType } from '../../../models/transaction-type.model';
import { AppListingSliderService } from '../listing-slider/app-listing-slider.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
export class ListingCardComponent {
    constructor(loginFormDialog, imageService, router, detailPositionService, carouselRestoreService, platformConfigHelper, dialog, commonService, commomMapSvc, breakpointObserver, propertyHelperService, i18nService, ngNavigatorShareService, toastCtrl, listingService, auth, _eventChecker, _ts, userActivityService, coworkingService, listingSliderService) {
        this.loginFormDialog = loginFormDialog;
        this.imageService = imageService;
        this.router = router;
        this.detailPositionService = detailPositionService;
        this.carouselRestoreService = carouselRestoreService;
        this.platformConfigHelper = platformConfigHelper;
        this.dialog = dialog;
        this.commonService = commonService;
        this.commomMapSvc = commomMapSvc;
        this.breakpointObserver = breakpointObserver;
        this.propertyHelperService = propertyHelperService;
        this.i18nService = i18nService;
        this.ngNavigatorShareService = ngNavigatorShareService;
        this.toastCtrl = toastCtrl;
        this.listingService = listingService;
        this.auth = auth;
        this._eventChecker = _eventChecker;
        this._ts = _ts;
        this.userActivityService = userActivityService;
        this.coworkingService = coworkingService;
        this.listingSliderService = listingSliderService;
        this.isMobile = false;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.counter = 0;
        this.mapOptions = {
            singleMarker: true,
            ignoreZoom: true,
            zoom: 15
        };
        this.photosSet = false;
        this.count = 0;
        this.faShareAltSquare = faShareAltSquare;
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
            if (!this.isMobile) {
                const mqStandAlone = '(display-mode: standalone)';
                if (window.matchMedia(mqStandAlone).matches) {
                    this.isMobile = window.matchMedia(mqStandAlone).matches;
                }
            }
        });
    }
    ngOnInit() {
        const isOffice = this.commonService.isPropertyType(2001, this.listing);
        if (this.listing.listingPhotos) {
            this.removeMap();
        }
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res) => {
            this.customLoginText = res;
        });
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        this.faIcon = this.listing && this.listing.listingType.toLowerCase() === 'featured' ? faStar : faAward;
        this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
        this.markers = [this.commonService.createMapMarker(this.listing)];
        this.staticMapImageUrl = this.commomMapSvc.generateStatiMapRequest(this.listing);
        this.listing.listingPhotos.push(this.staticMapImageUrl);
        this.photosSet = true;
        this.faHeart = faHeart;
        this.faExternalLinkAlt = faExternalLinkAlt;
        this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(this.listing.buildingTypeId);
        if (this.listing.propertySubTypes && this.listing.propertySubTypes.length) {
            const propertySubTypes = this.commonService.translateLocaleText(this.listing.propertySubTypes, 'name');
            this.listingSubtypes = this.commonService.transformArrayStringList(propertySubTypes, 'name');
        }
        this.setSharedPrivatePositionsText();
    }
    ngOnDestroy() {
        this.loginSubscription.unsubscribe();
        this.translateSubscription.unsubscribe();
    }
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
    }
    removeMap() {
        Object.keys(this.listing.listingPhotos).forEach(key => {
            if (this.listing.listingPhotos[key] && !this.listing.listingPhotos[key].image) {
                this.listing.listingPhotos.splice(key, 1);
            }
        });
    }
    isEndOfSlide() {
        return this.currentPhotoIndex === this.listing.listingPhotos.length - 1;
    }
    getCroppedOrOriginalImage(listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    }
    getMediumImg(id) {
        return this.imageService.mediumThumbnail(id);
    }
    getCompanyLogoImg(id) {
        return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
    }
    slideToIndex(event, index) {
        event.stopPropagation();
        this.photoSlider.slideTo(index);
    }
    openEditListingMenu(event, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            event.stopPropagation();
            data = yield this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data);
            this.dialogRef = this.dialog.open(MyListingEditComponent, {
                height: 'auto',
                width: '550px',
                data: data ? { data: data, myListingPage: this.myListingPage, date: new Date() } : null
            });
            this.dialogRef.afterClosed().subscribe((result) => { });
        });
    }
    isFavoriteListing() {
        return this.listing ? this.listing.favorite : false;
    }
    setFavoriteLisitng(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopPropagation();
            if (!this.isLoggedIn) {
                this.openLoginDialog()
                    .afterClosed()
                    .subscribe(() => {
                    if (this.isLoggedIn) {
                        this.toggleFavorite();
                    }
                });
            }
            else {
                this.toggleFavorite();
            }
        });
    }
    toggleFavorite() {
        this.listingService.toggleFavoriteListing(this.listing, this.listing.favorite || false).subscribe(() => {
            this._eventChecker.updateListing$.next(Object.assign({}, this.listing, { favorite: !this.listing.favorite }));
        });
    }
    openLoginDialog() {
        const currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    }
    openCard2() {
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.listing,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.afterClosed().subscribe((result) => { });
    }
    openCard(id, $event, externalLink = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const state = {
                localStorageKey: this.localStorageKey,
                searchCriteria: this.searchCriteria,
                searchTags: this.searchTags,
                checkBoxValues: this.checkBoxValues,
                myListingAnalytics: this.myListingAnalytics,
                myListingPage: this.myListingPage,
                openUrl: true
            };
            $event.stopImmediatePropagation();
            let lang = this.i18nService.getCurrentLanguage();
            if (window.location.href.includes('/pt-br')) {
                lang = 'pt-br';
            }
            else if (window.location.href.includes('/en')) {
                lang = 'en';
            }
            let res = yield this.listingService.checkListingDetailRoute(id, lang).toPromise();
            if (res) {
                let detailsURL = res.detailsURL;
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                }
                if (externalLink) {
                    window.open(detailsURL, '_blank');
                    return;
                }
                if (this.isMobile) {
                    this.router.navigateByUrl(detailsURL, { state: state });
                }
                else {
                    window.open(detailsURL, '_blank');
                }
                this.detailPositionService.setPositionData(this.activeListingIds);
            }
        });
    }
    routeToDetailsPage(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = yield this.listingService.checkListingDetailRoute(id, 'en').toPromise();
            if (res) {
                let searchPageURL = res.detailsURL;
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    searchPageURL = this.i18nService.addCurrentLanguageToPath(`/detail/${id}`).split('/');
                }
                if (!searchPageURL[0].startsWith('/')) {
                    searchPageURL[0] = '/'.concat(searchPageURL[0]);
                }
                return searchPageURL;
            }
        });
    }
    getLargeImg(id) {
        return this.imageService.largeThumbnail(id);
    }
    getFullImagePath(id) {
        return this.imageService.getFullImagePath(id);
    }
    getPreviousPhoto() {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listing.listingPhotos.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    }
    getNextPhoto(id, $event) {
        this.photoSlider.getActiveIndex().then(index => {
            if (index < this.currentPhotoIndex) {
                this.currentPhotoIndex = index;
            }
            else {
                if (this.currentPhotoIndex === this.listing.listingPhotos.length - 1 && this.count == 0) {
                    this.openCard(id, $event);
                }
                else {
                    if ($event.type === 'click') {
                        this.photoSlider.slideNext();
                        this.updatePhotoIndex();
                        this.count = 1;
                    }
                    else {
                        if (this.count == 1) {
                            this.count = 0;
                        }
                        else {
                            this.updatePhotoIndex();
                        }
                    }
                }
            }
        });
    }
    updatePhotoIndex() {
        this.photoSlider.getActiveIndex().then(index => {
            this.currentPhotoIndex = index;
        });
    }
    getUrlUpdate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let lang = this.i18nService.getCurrentLanguage();
            let res = yield this.listingService.checkListingDetailRoute(this.listing.id, lang).toPromise();
            let detailsURL;
            if (res) {
                detailsURL = res.detailsURL;
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                }
            }
            return detailsURL;
        });
    }
    shareLink($event, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            $event.stopImmediatePropagation();
            this.userActivityService.trackListingShare(listingId);
            let res = yield this.getUrlUpdate();
            this.ngNavigatorShareService
                .share({
                title: this.i18nService.getTranslation(this.listing.title),
                text: this.i18nService.get('global.listing-card.shareLinkText'),
                url: environment.spotServerUrl + res
            })
                .catch((error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (error) {
                    let text = environment.spotServerUrl + res;
                    let result = this.copyTextToClipboard(text);
                    if (result) {
                        const toast = yield this.toastCtrl.create({
                            message: this.i18nService.get('global.listing-card.desktopShareText'),
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert'
                        });
                        toast.onDidDismiss().then(() => { });
                        return yield toast.present();
                    }
                }
            }));
            return false;
        });
    }
    copyTextToClipboard(text) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var txtArea = document.createElement('textarea');
            txtArea.id = 'txt';
            txtArea.style.position = 'fixed';
            txtArea.style.top = '0';
            txtArea.style.left = '0';
            txtArea.style.opacity = '0';
            txtArea.value = text;
            document.body.appendChild(txtArea);
            txtArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                if (successful) {
                    return true;
                }
            }
            catch (err) {
            }
            finally {
                document.body.removeChild(txtArea);
            }
            return false;
        });
    }
    addColon(propertySubTypes) {
        return propertySubTypes && propertySubTypes.length ? ':' : '';
    }
    getMeasurementValue() {
        if (this.listing.measurementStandard) {
            return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
        }
        else {
            return this.i18nService.get('global.coworking.availableSpace');
            //return 'Available Space!';
        }
    }
    hasSubtypeOnMobile() {
        return [
            this.isLoadFromMain,
            this.isMobile,
            this.listing,
            this.listing.propertySubTypes && this.listing.propertySubTypes.length
        ].every(condition => condition);
    }
    isCompanySameAsAUser(listing) {
        return listing && listing.offeredByUser.company.name === listing.offeredByUser.firstNameAndLastName;
    }
    showPrevNextNav() {
        return [
            this.hasPointer,
            this.listing.listingPhotos && this.listing.listingPhotos.length > 1,
            !this.myListingPage
        ].every(condtion => condtion);
    }
    viewMap($event) {
        $event.stopImmediatePropagation();
        this.updatePhotoIndex();
        this.photoSlider.slideTo(this.listing.listingPhotos.length);
    }
    loopSlide() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const numberOfSlide = this.listing.listingPhotos.length;
            const isBeginging = yield this.photoSlider.isBeginning();
            const isEnd = yield this.photoSlider.isEnd();
            const prev = yield this.photoSlider.getPreviousIndex();
            if (this.currentPhotoIndex === 0 && isBeginging) {
                this.photoSlider.slideTo(this.listing.listingPhotos.length);
            }
            else if (this.currentPhotoIndex === numberOfSlide - 1 && isEnd) {
                this.photoSlider.slideTo(0);
            }
            this.updatePhotoIndex();
        });
    }
    isRentCondoIPTUNegotiables() {
        return ((!this.listing.monthlyRent || this.listing.monthlyRent == 0) &&
            (!this.listing.monthlyCondoFee || this.listing.monthlyCondoFee == 0) &&
            (!this.listing.monthlyIptu || this.listing.monthlyIptu == 0));
    }
    hasCoworkingSubType(listing) {
        return listing.isForLeaseSale === ListingOfferType.Coworking;
    }
    setSharedPrivatePositionsText() {
        const showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
        this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(this.listing, showCoworkingPrivate);
    }
    checkRole(role) {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.log('roles undefined --> ', e);
        }
    }
    buildListingDetailsURL(listingId) {
        return `${environment.spotServerUrl}/detail/${listingId}`.concat(this.i18nService.getLanguagePathURL());
    }
    changeSlide(event) {
        let swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
        this.listingSliderService.setSlide(swipeDirection);
    }
}
