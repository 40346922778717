import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { SpotCardSliderService } from '../services/spot-card-slider.service';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
export class AppSpotCardSlider {
    constructor(spotCardSliderSvc, breakpointObserver) {
        this.spotCardSliderSvc = spotCardSliderSvc;
        this.breakpointObserver = breakpointObserver;
        this.noDataMsg = 'No Data Found';
        this.cardContainerClass = '';
        this.spotBuildingSearchPayload = cloneDeep(this.spotCardSliderSvc.defaultSearchRequest);
        this.favoriteChangeEvent = new EventEmitter();
        this.totalCountEvent = new EventEmitter();
        this.firstFetch = true;
        this.queryPage = 1;
        this.disabledNext = false;
        this.disabledPrev = true;
        this.allSpotIds = [];
        this.spotBuildings = [];
        this.loading = false;
        this.activeListingIds = [];
        this.changeSlide$ = new Subject();
        this.slideOptionOverride = Object.assign({}, this.spotCardSliderSvc.defaultSliderOpts, { breakpoints: this.breakpoints || this.spotCardSliderSvc.defaultSliderOpts.breakpoints });
        this.promotedCards = [1, 2, 3];
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
    }
    ngOnInit() {
        if (!this.isPromoted) {
            this.fetchSpotBuildings();
        }
    }
    compareObjects(previous, current) {
        return JSON.stringify(previous) === JSON.stringify(current);
    }
    ngOnChanges(changes) {
        const payload = changes.spotBuildingSearchPayload;
        let goToRefresh = false;
        if (payload) {
            const previousPayload = payload.previousValue;
            const currentValue = payload.currentValue;
            if (previousPayload) {
                if (!this.compareObjects(previousPayload, currentValue)) {
                    goToRefresh = true;
                }
            }
            if (!this.compareObjects(currentValue, this.spotBuildingSearchPayload)) {
                goToRefresh = true;
            }
        }
        console.log('Changes Slider: ', changes, this.spotBuildingSearchPayload, goToRefresh);
        if (goToRefresh) {
            this.refresh();
        }
    }
    refresh() {
        this.clear();
        if (!this.isPromoted) {
            this.fetchSpotBuildings();
        }
    }
    clear() {
        this.allSpotIds = [];
        this.spotBuildings = [];
    }
    fetchSpotBuildings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                this.spotCardSliderSvc
                    .searchAllSpotIdsByCompany(this.spotBuildingSearchPayload)
                    .then((results) => {
                    const spotBuildingIds = results.body;
                    if (spotBuildingIds.length % 2 == 0) {
                        this.querySize = Math.round(spotBuildingIds.length / 2);
                        this.querySize = !this.isMobile && this.querySize > 2 ? this.querySize : 3;
                    }
                    else {
                        this.querySize = Math.ceil(spotBuildingIds.length / 3);
                    }
                    if (spotBuildingIds) {
                        this.spotCardSliderSvc
                            .searchSpotsByCompanyAsync(spotBuildingIds, this.queryPage, this.querySize)
                            .then((result) => {
                            const spotBuildings = result;
                            this.firstFetch = false;
                            this.allSpotIds = spotBuildingIds;
                            this.updateCounts();
                            this.spotBuildings = this.allSpotIds.map(() => null);
                            this.concatSpotBuildings(spotBuildings);
                            this.loading = false;
                        });
                    }
                });
            }
            catch (error) {
                this.loading = false;
                this.firstFetch = false;
                this.handleError(error);
            }
        });
    }
    updateCounts() {
        this.totalCountEvent.emit(this.allSpotIds ? this.allSpotIds.length : 0);
    }
    fetchMore({ pageSize, pageNumber }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.loading = true;
                this.queryPage = pageNumber;
                const spotBuildings = yield this.spotCardSliderSvc.getSpotBuildingsPaginated(this.allSpotIds, pageNumber, pageSize);
                this.concatSpotBuildings(spotBuildings);
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
                this.handleError(error);
            }
        });
    }
    onSlideChange(direction) {
        this.changeSlide$.next(direction);
    }
    concatSpotBuildings(spotBuildings) {
        if (spotBuildings && spotBuildings.length) {
            let copy = cloneDeep(this.spotBuildings);
            const loadedIndex = (this.queryPage - 1) * this.querySize;
            copy.splice(loadedIndex, spotBuildings.length, ...spotBuildings);
            this.spotBuildings = copy;
        }
    }
    handleError(error) {
        this.loading = false;
        this.error = error;
    }
    onSliderUpdate({ isEnd, isBeginning }) {
        this.disabledNext = !!isEnd;
        this.disabledPrev = !!isBeginning;
    }
    favoriteChange(changedFavorite) {
        if (this.onlyFavorites) {
            this.keepFavoriteSpots(changedFavorite);
            this.favoriteChangeEvent.emit(changedFavorite);
            this.updateCounts();
        }
    }
    keepFavoriteSpots(updatedFavorite) {
        this.spotBuildings = this.spotBuildings.filter(spot => spot && spot.favorite);
        if (updatedFavorite && updatedFavorite.id) {
            this.allSpotIds = this.allSpotIds.filter(spotId => spotId != updatedFavorite.id);
        }
    }
}
