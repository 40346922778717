import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { I18nService } from '../i18n.service';
import { BehaviorSubject } from 'rxjs';
export class ListingDetailService {
    constructor(http, i18NService) {
        this.http = http;
        this.i18NService = i18NService;
        this.host = 'api/listings';
        this.listingPhotosEndpoint = 'api/listingPhotosByListing/image-formats-only';
        this.listingIdsEndpoint = `api/listings/displayable/fastIds`;
        this.publicProfilePhoto = 'api/account/photoByUserLogin';
        this.listingPropertySubtype = 'api/lookUpTables/listings/:id/property-subtypes';
        this.favoritesCountSubject = new BehaviorSubject(0);
        this.getUserInfo = 'api/listings/userinfo';
        this.listingDetails = 'api/listings/:id/details';
        this.buildingNames = 'api/buildings/buildingNames';
        this.favoriteListing = 'api/favorites/';
        this.subscribe = 'api/mailing-subscription/';
        this.revisionChangesAPI = 'revision-changes';
    }
    getListingById(listingId) {
        return this.http.get(`${this.host}/${listingId}`, { observe: 'response' }).pipe(map(val => val.body));
    }
    getListingPhotos(listingId) {
        return this.http
            .get(`${this.listingPhotosEndpoint}?listingId=${listingId}`, { observe: 'response' })
            .pipe(map(val => val.body));
    }
    getListingDetails(id) {
        return this.http.get(`${this.host}/${id}/detail`, { observe: 'response' }).pipe(map(val => val.body));
    }
    getListingDetailsSummarized(baseId, id, lang) {
        return this.http
            .get(`${this.host}/${baseId}/${id}/detail/${lang}/summarized`, { observe: 'response' })
            .pipe(map(val => val.body));
    }
    checkListingDetailRoute(id, lang) {
        return this.http
            .get('api/listings/' + id + '/detail/url-data/' + lang, { observe: 'response' })
            .pipe(map(val => val.body));
    }
    mostRecentActiveListingFromBaseId(baseId, lang) {
        return this.http
            .get('api/listings/' + baseId + '/detail/most-recent/active/' + lang, { observe: 'response' })
            .pipe(map(val => val.body));
    }
    getListingPropertySubtype(id) {
        return this.http
            .get(`api/lookUpTables/listings/${id}/property-subtypes`, { observe: 'response' })
            .pipe(map(val => val.body));
    }
    getProfilePicture(login) {
        return this.http.get(`${this.publicProfilePhoto}/${login}`, { observe: 'response' }).pipe(map(val => val.body));
    }
    getAllListingsIds() {
        return this.http.get(this.listingIdsEndpoint).toPromise();
    }
    getBuildingNames() {
        return this.http.get(`${this.buildingNames}`, { observe: 'response' }).pipe(map(val => val.body));
    }
    getUserById(id, listingId) {
        return this.http
            .get(listingId ? `${this.getUserInfo}/${id}?listingId=${listingId}` : `${this.getUserInfo}/${id}`, {
            observe: 'response'
        })
            .pipe(map(val => val.body));
    }
    getFavoriteListings() {
        return this.http.get(this.favoriteListing);
    }
    updateFavoritesCount() {
        this.getFavoriteListings().subscribe((val) => {
            this.favoritesCountSubject.next(val.filter(l => l.listingStatus.id == 4).length);
        });
    }
    toggleFavoriteListing(listing, isFavorite) {
        let currentFavoritesCount = this.favoritesCountSubject.getValue();
        if (isFavorite) {
            return this.http.delete(`${this.favoriteListing}${listing.id}/unmark`).pipe(tap(() => {
                if (this.isActiveListing(listing)) {
                    this.favoritesCountSubject.next(currentFavoritesCount - 1);
                }
            }));
        }
        else {
            return this.http.put(`${this.favoriteListing}${listing.id}/mark`, {}).pipe(tap(() => {
                if (this.isActiveListing(listing)) {
                    this.favoritesCountSubject.next(currentFavoritesCount + 1);
                }
            }));
        }
    }
    isActiveListing(listing) {
        return listing && listing.listingStatus.id == 4;
    }
    getListingDetailsURLFrom(listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let lang = this.i18NService.getCurrentLanguage();
            let res = yield this.checkListingDetailRoute(listingId, lang).toPromise();
            let detailsURL = '/detail/' + listingId;
            if (res) {
                detailsURL = res.detailsURL;
                if (this.i18NService.currentUrlContainsCurrentLanguage()) {
                    detailsURL = this.i18NService.addCurrentLanguageToPath(detailsURL);
                }
            }
            return detailsURL;
        });
    }
    checkRole(role) {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.log('roles undefined --> ', e);
        }
    }
    getRevisionOrActiveVersionBasedOnUserRole(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (data.listingStatus.id == 4) {
                if (data.nextListingId && data.nextListingStatus) {
                    if (data.nextListingStatus.id == 8) {
                        const isSpotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
                        if (!isSpotAdmin) {
                            data = yield this.getListingById(data.nextListingId).toPromise();
                        }
                    }
                }
            }
            return data;
        });
    }
    subscription(mail) {
        return this.http.post(`${this.subscribe}`, mail).pipe(map(val => val));
    }
    revisionChanges(activeListingId, revisionListing, lang) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const revisionChangesMap = yield this.http
                .post(`${this.host}/${activeListingId}/${this.revisionChangesAPI}/${lang}`, revisionListing)
                .toPromise();
            return revisionChangesMap;
        });
    }
}
